<template slot-scope="props">
    <div class="details-item-top-banner bg bg-cover pt-8 pb-5 px-3 pl-5 pr-5">
        <div v-if="animal==='bull' && bullLoading">
            <div class=" row text-white fon">
                <div class="col-12 text-center">
                    <loading-round/>
                    <div class="mt-3">Please wait while we fetching the data . . .</div>
                </div>
            </div>
        </div>
        <div v-else-if="animal=== 'cow' && cowLoading">
            <div class=" row text-white fon">
                <div class="col-12 text-center">
                    <loading-round/>
                    <div class="mt-3">Please wait while we fetching the data . . .</div>
                </div>
            </div>
        </div>
        <div v-else>
        <div class="fl-x-br mb-n3">
            <btn class="px-41 btn-2 b-1 b-light mb-n4 text-white z-index-1" color="third" @click="setEdit">Edit</btn>
        </div>
        <row>
            <col-12 lg="3" class="fl-x fl-a-c">
                <upload-image :image-u-r-l="bullDetails.image" :animal="animal" class="m-2"></upload-image>
            </col-12>
            <col-12 lg="9">
                <row>
                    <col-12 lg="3" class="pr-0">
                        <details-item label="Name"  v-model="bullDetails.bullname"  v-if="animal=== 'bull'"></details-item>
                        <details-item label="Name"  v-model="bullDetails.cowname"  v-if="animal=== 'cow'"></details-item>
                    </col-12>
                    <col-12 lg="3" class="pr-0">
                        <details-item label="AI Date"  v-model="bullDetails.aidate"></details-item>
                    </col-12>
                    <col-12 lg="3" class="pr-0">
                        <details-item label="Dam Breeding Value" v-model="bullDetails.dam_breeding_value"></details-item>
                    </col-12>
<!--                    <col-12 lg="3" class="pr-0">
                        <details-item label="Vaccination Date"  v-model="bullDetails.last_vaccination_date"></details-item>
                    </col-12>-->
                    <col-12 lg="3" class="pr-0">
                        <details-item label="Registration Number"  v-model="bullDetails.regno"></details-item>
                    </col-12>
                    <col-12 lg="3" class="pr-0">
                        <details-item label="Purchase Value" v-model="bullDetails.purchasevalue"></details-item>
                    </col-12>
                    <col-12 lg="3" class="pr-0">
                        <details-item label="Dam’s Milk Fat %"  v-model="bullDetails.milk_fat"></details-item>
                    </col-12>
                    <col-12 lg="3" class="pr-0">
                        <details-item label="Source Type " v-model="bullDetails.registration_type"></details-item>
                    </col-12>
                    <col-12 lg="3" class="pr-0">
                        <details-item label="Re Number" v-model="bullDetails.renumber"></details-item>
                    </col-12>
                    <col-12 lg="3" class="pr-0">
                        <details-item label="Dehorning Date"  v-model="bullDetails.dehorndate"></details-item>
                    </col-12>
                    <col-12 lg="3" class="pr-0">
                        <details-item label="Dam’s Protein %"  v-model="bullDetails.protein"></details-item>
                    </col-12>
                    <col-12 lg="3" class="pr-0">
                        <details-item label="Source"  v-model="bullDetails.source_name"></details-item>
                    </col-12>
                    <col-12 lg="3" class="pr-0">
                        <details-item label="Date of Birth" v-model="bullDetails.dob"></details-item>
                    </col-12>
                    <col-12 lg="3" class="pr-0">
                        <details-item v-if="this.animal==='bull'" label="Nose Punching Date"  v-model="bullDetails.nosepunchdate"></details-item>
                        <details-item v-else label="Nose Punching Date"  v-model="bullDetails.nose_punching_date"></details-item>
                    </col-12>
                    <col-12 lg="3" class="pr-0">
                        <details-item label="Sire Number" v-model="bullDetails.sireno"></details-item>
                    </col-12>
                    <col-12 lg="3" class="pr-0">
                        <details-item label="Unit" v-model="bullDetails.unit"></details-item>
                    </col-12>
                    <col-12 lg="3" class="pr-0">
<!--                        <details-item label="Shed Number" v-if="bullDetails.shed_number"  v-model="bullDetails.shed_name"></details-item>
                        <details-item label="Shed Number"  v-else> -&#45;&#45; </details-item> -->
                        <details-item label="Shed " v-if="this.animal==='bull'"  v-model="bullDetails.shed_name"></details-item>
                        <details-item label="Shed "  v-else v-model="bullDetails.shed_name"> --- </details-item>
                    </col-12>
                    <col-12 lg="3" class="pr-0">
                        <details-item label="Dam's Name"  v-model="bullDetails.dam_name"></details-item>
                    </col-12>
                    <col-12 lg="3" class="pr-0">
                        <details-item label="Sire Name" v-model="bullDetails.sire_name"></details-item>
                    </col-12>
                    <col-12 lg="3" class="pr-0">
                        <details-item label="Transfer Date"  v-model="bullDetails.transferdate"></details-item>
                    </col-12>
                    <col-12 lg="3" class="pr-0">
                        <details-item label="Breeding Value"  v-model="bullDetails.breeding_value"></details-item>
                    </col-12>
                    <col-12 lg="3" class="pr-0">
                        <details-item label="Dam's Number"  v-model="bullDetails.damno"></details-item>
                    </col-12>
                    <col-12 lg="3" class="pr-0">
                        <details-item label="Sire Breed"  v-model="bullDetails.sirebreed_name"></details-item>
                    </col-12>
                    <col-12 lg="3" class="pr-0">
                        <details-item label="Old Number"  v-model="bullDetails.old_number"></details-item>
                    </col-12>
                    <col-12 lg="3" class="pr-0">
                        <details-item label="Breed"   v-model="bullDetails.breed_name"></details-item>
<!--                        <details-item label="Shed Number"  v-else> -&#45;&#45; </details-item>-->
                    </col-12>
                    <col-12 lg="3" class="pr-0">
                        <details-item label="Dam’s Breed" v-model="bullDetails.dambreed_name"></details-item>
                    </col-12>
                    <col-12 lg="3" class="pr-0">
                        <details-item label="Sire Dam Yield"  v-model="bullDetails.siredamyield"></details-item>
                    </col-12>
                    <col-12 lg="3" class="pr-0">
                        <details-item label="Country/State/Region"  v-model="bullDetails.country_state_region"></details-item>
                    </col-12>
                    <col-12 lg="3" class="pr-0">
                        <details-item label="Birth Weight"  v-model="bullDetails.birthwt"></details-item>
                    </col-12>
                    <col-12 lg="3" class="pr-0">
                        <details-item label="Dams Lactation Yield"  v-model="bullDetails.damlacyield"></details-item>
                    </col-12>
                    <col-12 lg="3" class="pr-0">
                        <details-item label="Sire Daughter’s Yield" v-model="bullDetails.siredaughteryield"></details-item>
                    </col-12>
                    <col-12 lg="3" class="pr-0">
                        <details-item label="Remarks" value-font-size="sm" v-model="bullDetails.remarks"
                                      value=""></details-item>
                    </col-12>
                    <col-12 lg="3" class="">
                        <details-item label="F1 calf or not"  v-model="bullDetails.f1status"></details-item>
                    </col-12>
                    <col-12 lg="3" class="">
                        <details-item label="Dam’s Lactation No"  v-model="bullDetails.damlacno"></details-item>
                    </col-12>
                    <col-12 lg="3" class="">
                        <details-item label="Projected Yield of Daughter"  v-model="bullDetails.projected_yield_of_daughter"></details-item>
                    </col-12>
                    <col-12 lg="3">&nbsp;</col-12>
                    <col-12 lg="3" class="">
                        <details-item label="Addition Date"  v-model="bullDetails.additiondate"></details-item>
                    </col-12>
                    <col-12 lg="3" class="">
                        <details-item label="Grand Dam Yield"  v-model="bullDetails.granddamyield"></details-item>
                    </col-12>
<!--                    <col-12 lg="3" class="">
                        <details-item label="Vaccination Type"  v-model="bullDetails.vactype"></details-item>
                    </col-12>-->
<!--                    <col-12 lg="3" class="">-->
<!--                        <btn class="px-4 btn-2 b-1 b-light" color="fourth">Update</btn>-->
<!--                    </col-12>-->

                </row>
            </col-12>
        </row>
            </div>
        <modal title="Edit Details"  ref="bullEditModal" :no-close-on-backdrop="true" width="60r" header-color="primary">
            <EditItem ref="editPage" :animal=animal :model="editingItem" @success="formSuccess"></EditItem>
        </modal>
    </div>

</template>

<script>
import DetailsItem from '../../../components/details-page/DetailsItem';
import UploadImage from '../../../components/HerdManagement/upload-image';
import axios from 'secure-axios';
import urls from '../../../data/urls';
import EditItem from '../../../components/details-page/EditItem';

export default {
    name: 'TopGeneralInfoItems',
    data () {
        return {
            bullLoading: false,
            cowLoading: false,
            loading: false,
            editingItem: '',
            bullDetails: ''
        };
    },
    props: {
        animal: {
            type: String

        }
    },
    components: { EditItem, UploadImage, DetailsItem },
    mounted () {
        if (this.animal === 'cow') {
            this.loadDetailsCow();
        } else {
            this.loadDetails();
        }
    },

    methods: {
        refreshData () {
            if (this.animal === 'cow') {
                this.loadDetailsCow();
            } else {
                this.loadDetails();
            }
        },
        formSuccess (data) {
            this.$refs.bullEditModal.close();
            this.editingItem = '';
            this.bullDetails = data;
        },

        setEdit () {
            this.editingItem = { ...this.bullDetails };
            this.$refs.bullEditModal.show();
        },
        async loadDetailsCow () {
            try {
                this.cowLoading = true;
                const bullID = this.$route.params.id;
                const animal = this.$route.params.animal;
                const response = await axios.form(urls.cowDetails.cowDetails, { id: bullID });
                console.log('response: ', response.data.data);
                console.log('id: ', this.$route.params.id);
                console.log('animal: ', animal);
                this.bullDetails = response.data.data;
                this.cowLoading = false;
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
                this.bullLoading = false;
            }
        },
        cleanNullValues (obj) {
            for (const key in obj) {
                if (obj[key] === null) {
                    this.$set(obj, key, '');
                }
            }
            return obj;
        },
        async loadDetails () {
            try {
                this.bullLoading = true;
                const bullID = this.$route.params.id;
                const animal = this.$route.params.animal;
                const response = await axios.form(urls.bullDetails.bullDetailsDetail, { id: bullID });
                console.log('response: ', response.data.data);
                console.log('id: ', this.$route.params.id);
                console.log('animal: ', animal);
                const data = response.data.data;
                this.bullDetails = this.cleanNullValues(data);
                console.log('degtails', this.bullDetails);
                this.bullLoading = false;
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
                this.bullLoading = false;
            }
        }

    }
};
</script>

<style scoped>
    .details-item-top-banner {
        background-image: url('../../../assets/web/details-page/details-bg.png');
    }
</style>
